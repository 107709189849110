import React from 'react';
import { Row, Col } from 'reactstrap';

const WhyUSFreelancersComponent = () => {
    return (
        <div>
            <div class="justify-content-center spacer feature1">
                <div class="container">
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                            <h2 className="title">Pourquoi nous chosir ?</h2>
                            <h6 className="subtitle">Nous vous proposons un accompagnement sur-mesure pour bien répondre à votre besoin</h6>
                        </Col>
                    </Row>
		        </div>
                <div class="row">
                    <div class="col-md-4 wrap-feature1-box">
                        <div class="card " data-aos="fade-right" data-aos-duration="1200">
                            <div class="card-body text-center">
                                <div class="icon-space"><i class="display-5 icon-Car-Wheel"></i></div>
                                <h5 class="font-medium">Première mission en freelance ?</h5>
                                <p class="m-t-20">Nous vous accompagnons dans la recherche de votre première mission freelance auprès de nos clients, ainsi que dans la sélection de votre partenaire de portage salarial.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 wrap-feature1-box">
                        <div class="card " data-aos="fade-up" data-aos-duration="1200">
                            <div class="card-body text-center">
                                <div class="icon-space"><i class="display-5 icon-Target"></i></div>
                                <h5 class="font-medium">Une mission sur mesure</h5>
                                <p class="m-t-20">Une mission personnalisée vous sera proposée en fonction de votre expertise, de votre tarif journalier moyen (TJM) et de vos disponibilités.<br/>Un suivi sera assuré tout au long de votre mission.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 wrap-feature1-box">
                        <div class="card " data-aos="fade-left" data-aos-duration="1200">
                            <div class="card-body text-center">
                                <div class="icon-space"><i class="display-5 icon-Mouse-3"></i></div>
                                <h5 class="font-medium">Paiement de votre prestation</h5>
                                <p class="m-t-20">Votre paiement sera automatiquement effectuée une fois que votre feuille de temps signée par le client aura été soumise.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyUSFreelancersComponent;
