import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import img1 from '../../../assets/images/whyUS/Cout.jpg';
import img2 from '../../../assets/images/whyUS/Expertise.png';
import img3 from '../../../assets/images/whyUS/Professionalisme.jpg';
import img4 from '../../../assets/images/whyUS/Flexibilite.jpg';

const WhyUSCompaniesComponent = () => {
    const [toggleBloc1, setToggleBloc1] = React.useState(true);
    const [toggleBloc2, setToggleBloc2] = React.useState(false);
    const [toggleBloc3, setToggleBloc3] = React.useState(false);
    const [toggleBloc4, setToggleBloc4] = React.useState(false);
    
    const showBloc1 = () =>{
        setToggleBloc1(true);
        setToggleBloc2(false);
        setToggleBloc3(false);
        setToggleBloc4(false);
    }

    const showBloc2 = () =>{
        setToggleBloc1(false);
        setToggleBloc2(true);
        setToggleBloc3(false);
        setToggleBloc4(false);
    }

    const showBloc3 = () =>{
        setToggleBloc1(false);
        setToggleBloc2(false);
        setToggleBloc3(true);
        setToggleBloc4(false);
    }

    const showBloc4 = () =>{
        setToggleBloc1(false);
        setToggleBloc2(false);
        setToggleBloc3(false);
        setToggleBloc4(true);
    }

    return (
        <div>
            <div class="justify-content-center feature1">
                <div class="bg-light spacer feature41">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-md-7 text-center">
                                <h2 class="title">Équipe expérimentée et professionnelle</h2>
                                <h6 class="subtitle">Forts de 20 ans d’expertise dans le domaine de l’IT, nous mettons nos compétences à votre service pour répondre pleinement à vos besoins et maximiser vos avantages.</h6>
                            </div>
                        </div>
                      
                        <div class="row m-t-40 wrap-feature41-box">
                            <div class="col-lg-4">
                                <ul class="nav vtab f41-tab">
                                    <li class="nav-item">
                                        <Link onClick={showBloc1} class={toggleBloc1 ? "nav-link active" : "nav-link"} id="cout-tab">
                                            <div class="display-5 t-icon"><i class="icon-Dollar-Sign"></i></div>
                                            <div>
                                                <h5 class="m-b-0">Le coût</h5>
                                                <h6 class="subtitle">Engager un freelance : une solution flexible et économique pour vos projets</h6>
                                            </div>
                                        </Link>
                                    </li>
                                    <li class="nav-item">
                                        <Link onClick={showBloc2} class={toggleBloc2 ? "nav-link active" : "nav-link"} id="expertise-tab">
                                            <div class="display-5 t-icon"><i class="icon-Brain-2"></i></div>
                                            <div>
                                                <h5 class="m-b-0">L'expertise</h5>
                                                <h6 class="subtitle">Freelances : des experts sur-mesure pour vos projets</h6>
                                            </div>
                                        </Link>
                                    </li>
                                    <li class="nav-item">
                                        <Link onClick={showBloc3} class={toggleBloc3 ? "nav-link active" : "nav-link"} id="professionalisme-tab">
                                            <div class="display-5 t-icon"><i class="icon-Structure"></i></div>
                                            <div>
                                                <h5 class="m-b-0">Le professionalisme</h5>
                                                <h6 class="subtitle">Freelance : un expert polyvalent au service de la satisfaction client</h6>
                                            </div>

                                        </Link>
                                    </li>
                                    <li class="nav-item">
                                        <Link onClick={showBloc4} class={toggleBloc4 ? "nav-link active" : "nav-link"} id="flexibilite-tab" >
                                            <div class="display-5 t-icon"><i class="icon-Data-Center"></i></div>
                                            <div>
                                                <h5 class="m-b-0">La fléxibilité</h5>
                                                <h6 class="subtitle">Freelances : flexibilité et réactivité pour s'adapter à vos besoins</h6>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                           
                            <div class="col-lg-7 ml-auto">
                                <div class="tab-content" id="myTabContent">
                                    <div class={toggleBloc1 ? "tab-pane fade show active" : "tab-pane fade show"} id="cout" style={{visibility: toggleBloc1 ? 'visible' : 'hidden'}}>
                                        <img src={img1} alt="Le Coût" class="rounded img-fluid" height={360}/>
                                        <p class="m-t-40">
                                            Faire appel à un freelance peut s'avérer plus économique que d'embaucher un employé à temps plein, puisque vous n’avez pas à couvrir les coûts liés aux avantages sociaux, aux congés payés, ou autres charges salariales.
                                            En outre, grâce à leur structure de coûts allégée, les freelances proposent souvent des tarifs horaires plus compétitifs que les entreprises de développement de logiciels. À noter que les freelances facturent uniquement les jours réellement travaillés, ce qui en fait une solution idéale pour garder une maîtrise totale sur le budget de votre projet.
                                        </p> 
                                    </div>
                                    <div class={toggleBloc2 ? "tab-pane fade show active" : "tab-pane fade show"} id="expertise" style={{visibility: toggleBloc2 ? 'visible' : 'hidden'}}>
                                        <img src={img2} alt="L'expertise" class="rounded img-fluid" height={360}/>
                                        <p class="m-t-40">
                                            Le freelancing vous offre la possibilité de sélectionner exactement les compétences dont votre projet a besoin.
                                            Spécialisés dans leur domaine, les freelances assurent une veille constante sur les évolutions de leur expertise. Cette démarche leur permet de rester à la pointe des innovations et de maintenir leur compétitivité.    
                                        </p>
                                    </div>
                                    <div class={toggleBloc3 ? "tab-pane fade show active" : "tab-pane fade show"} id="professionalisme" style={{visibility: toggleBloc3 ? 'visible' : 'hidden'}}>
                                        <img src={img3} alt="Le professionalisme" class="rounded img-fluid" height={360}/>
                                        <p class="m-t-40">
                                            Un freelance est à la fois un expert technique et un véritable chef d’entreprise. Il prend en charge la gestion de sa clientèle, sa comptabilité, sa communication, ses achats… et surtout, il veille à préserver sa réputation.
                                            Dans le marché très concurrentiel des freelances, garantir la satisfaction client est essentiel pour obtenir des recommandations et développer son activité. Compétence technique et respect des délais sont les piliers de son succès.
                                        </p>
                                    </div>
                                    <div class={toggleBloc4 ? "tab-pane fade show active" : "tab-pane fade show"} id="flexibilite" style={{visibility: toggleBloc4 ? 'visible' : 'hidden'}}>
                                        <img src={img4} alt="La flexibilité" class="rounded img-fluid" height={360}/>
                                        <p class="m-t-40">
                                            Votre projet peut nécessiter une expertise pour quelques mois, voire seulement quelques jours. Les freelances sont parfaitement capables de s’adapter à la durée exacte de vos besoins. Leur flexibilité horaire leur permet d’optimiser leur planning afin de répondre efficacement à vos demandes.
                                            Agiles et réactifs, ils s’adaptent rapidement aux évolutions de votre projet, offrant un soutien rapide et efficace.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    );
}

export default WhyUSCompaniesComponent;
