import React from "react";
import PropTypes from "prop-types";

// core components
import Header from "../../components/header/header.jsx";
import HeaderBanner from "../../components/banner/banner.jsx";
import Footer from "../../components/footer/footer.jsx";
import C2aComponent from "../custom-components/sections/c2acomponent.jsx";

// sections for this page
//import WhyUS from "../custom-components/sections/WhyUS.jsx";
import WhyUSFreelancers from "../custom-components/sections/WhyUSFreelancers.jsx";
import WhyUSCompanies from "../custom-components/sections/WhyUSCompanies.jsx";
import ContactComponent from "../custom-components/sections/contactcomponent.jsx";
import ProjectComponent from "../custom-components/sections/projectcomponent.jsx";


const HomeComponents = () => {
    return (
        <div id="main-wrapper">
            <Header />
            <div className="page-wrapper">
                <div className="container-fluid">
                <HeaderBanner />
                <WhyUSFreelancers />
                <ProjectComponent />
                <WhyUSCompanies />
                <C2aComponent />
                <ContactComponent />
                </div>
            </div>
            <Footer />
        </div>
    );
}

HomeComponents.propTypes = {
    classes: PropTypes.object
};

export default HomeComponents;
