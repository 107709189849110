/* eslint-disable */
import React from 'react';
import { Container } from 'reactstrap';

const C2aComponent = () => {
    return (
        <div>
            <div className="mini-spacer bg-info text-white c2a7">
                <Container>
                    <div className="d-flex justify-content-between">
                        <div className="display-7 align-self-center">Votre avis compte pour nous ! Dites-nous si nos services répondent à vos attentes.</div>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default C2aComponent;
