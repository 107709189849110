/* eslint-disable */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';

const Footer = () => {
    return (
        <div>
        <div className="footer4 b-t spacer">
            <Container>
                <Row>
                    <Col lg="3" md="6" className="m-b-30">
                        <h5 className="m-b-20">Adresse</h5>
                        <p>28 Rue Maurice Berteaux, 95870 Bezons</p>
                    </Col>
                    <Col lg="3" md="6" className="m-b-30">
                        <h5 className="m-b-20">Téléphone</h5>
                        <p>+33 6 58 18 93 30 <br />+33 6 28 79 42 13</p>
                    </Col>
                    <Col lg="3" md="6" className="m-b-30">
                        <h5 className="m-b-20">Email</h5>
                        <p><a href="mailto:contact@oxanium.com" className="link">contact@oxanium.com</a> <br /></p>
                    </Col>
                    <Col lg="3" md="6">
                        <h5 className="m-b-20">Suivez-nous</h5>
                        <div className="round-social light">
                            <a href="https://www.linkedin.com/company/oxanium-esn" target="_blank" className="link">
                                <i className="fa fa-linkedin"></i>
                            </a>
                        </div>
                    </Col>
                </Row>
                <div className="f4-bottom-bar">
                    <Row>
                        <Col md="12">
                            <div className="d-flex font-14 justify-content-between">
                                <div className="m-t-10 m-b-10 copyright">© OXANIUM - 2024 - Tous droits réservés</div>
                                <div className="links ms-auto m-t-10 m-b-10">
                                    <Link className="p-10" to={"/mentions-legales"}>
                                        Mentions légales
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
            
        </div>
        <a class="bt-top btn btn-circle btn-lg btn-info" href="#top"><i class="ti-arrow-up"></i></a>
        </div>
        
    );
}
export default Footer;
